import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Observable, throwError } from "rxjs";

import { Trakker } from "../_models/trakker.model"; // UNIMPLEMENTED
import { TranslateService } from "@ngx-translate/core";

import { retry, catchError } from "rxjs/operators";

import { map } from "rxjs/operators";
import { ApiService } from "./api.service";
import { ApiErrorHandlerService } from "./apierrorhandler/apierrorhandler.service";
import { Schedule } from "../_models/schedule.model";

@Injectable({
  providedIn: "root",
})
export class TrakkerService {
  trakkersChanged = new Subject<Trakker[]>();

  constructor(
    public translate: TranslateService,
    private api: ApiService,
    private apiErrorHandler: ApiErrorHandlerService
  ) {}

  // GET /api/trakkers
  getTrakkers2(filters: string = ""): Observable<any> {
    return this.api.get(`trakkers/${filters}`);
  }

  getTrakkers22(filters: string = ""): Observable<any> {
    return this.api.post("token", filters).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getSchedule = (trakkerId: number): Observable<Schedule> => {
    return this.api.get(`trakkers/${trakkerId}/schedule`);
  };

  // Error handling
  /*   handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
 */
  // GET ​/api​/v1​/trakkers  (Get trakkers for specified account)
  getTrakkers(filter: string = ""): Observable<any> {
    return this.api.get(`trakkers/${filter}`).pipe(retry(1));
  }

  // GET ​/api​/v1​/trakkers  (Get trakkers for specified account) -------- NEW CALL TO PREVENT ERROR IN UNITS-LIST AFTER ANGULAR UPDATE, 2021-09-29
  getTrakkersNew(filters: string = ""): Observable<any> {
    return this.api.get(`trakkers/${filters}`).pipe(
      retry(1)
      //     ,
      //  catchError(this.handleError)
      //  catchError(this.api.formatErrors)
    );
  }

  getTrakkerNew(trakkerId): Observable<any> {
    return this.api.get(`trakkers/` + trakkerId).pipe(retry(1));
  }

  // GET /api/trakkers/{trakkerId}
  getOneTrakkerOfCompany(trakkerId): Observable<any> {
    return this.api.get("trakkers/" + trakkerId).pipe(retry(1));
  }

  // PUT /api/trakkers/{trakkerId}
  editTrakkerData(trakkerId, data): Observable<any> {
    return this.api.put("trakkers/" + trakkerId, data).pipe(retry(1));
  }

  updateComment(trakkerId: number, newComment: string): Observable<any> {
    return this.api.post(`trakkers/${trakkerId}/comment`, { Comment: newComment });
  }

  // DELETE /api/trakkers/{trakkerId}
  deleteTrakker(trakkerId): Observable<any> {
    return this.api.delete("trakkers/" + trakkerId);
  }

  // GET /api/trakkers/{trakkerId}/event
  // getTrakkerEvents(trakkerId): Observable<any> {
  //   return this.api.get(
  //     'trakkers/' + trakkerId + '/event',
  //     this.parameters
  //   );
  // }

  // GET /api/trakkers/{trakkerId}/event (HMM, wrong?)
  // GET /api/trakkers/event
  getTrakkerEvents(trakkerId, filterUrl, pagerUrl): Observable<any> {
    return this.api.get("trakkers/event" + "?filter.trakkerIds=" + trakkerId + filterUrl + pagerUrl);
  }

  // NEW POST CALL
  // POST /api/trakkers/event
  fetchTrakkerEvents(data): Observable<any> {
    return this.api.post("trakkers/event", data);
  }

  // Function to fetch Excel or PDF report
  // POST /api/report/trakker/{trakkerId}/events
  getTrakkerEventReport(trakkerId, filter) {
    const newParams: any = Object.assign({});
    newParams.responseType = "blob";
    return this.api.post("report/trakker/" + trakkerId + "/events", filter, newParams);
  }

  // POST /api/trakkers/{trakkerId}/contact
  addTrakkerContact(trakkerId, contactId): Observable<any> {
    return this.api.post("trakkers/" + trakkerId + "/contact", contactId);
  }

  activateEmergencyMode(trakkerId, globalDeviceId): Observable<any> {
    return this.api.post("trakkers/" + trakkerId + "/hardware/" + globalDeviceId + "/EmergencyMode");
  }

  // DELETE /api/trakkers/{trakkerId}/contact/{contactId}
  deleteTrakkerContact(trakkerId, contactId): Observable<any> {
    return this.api.delete(
      "trakkers/" + trakkerId + "/contact/" + contactId
      // contactId
    );
  }

  // DELETE /api/trakkers/{trakkerId}/geofence/{geofenceId}
  deleteTrakkerGeofence(trakkerId, geofenceId): Observable<any> {
    return this.api.delete("trakkers/" + trakkerId + "/geofence/" + geofenceId);
  }

  // POST /api/trakkers/{trakkerId}/geofence
  addTrakkerGeofence(trakkerId, geofenceId): Observable<any> {
    return this.api.post("trakkers/" + trakkerId + "/geofence", geofenceId);
  }


  // GET /api/trakkers/{trakkerId}/activeAlerts
  getTrakkerActiveAlerts(trakkerId): Observable<any> {
    return this.api.get("trakkers/" + trakkerId +"/activeAlerts");
  }

  // GET /api/trakkers/active-alerts
  getTrakkersActiveAlerts(trakkerIds): Observable<any> {
    return this.api.post("trakkers/active-alerts", trakkerIds)
  }

  // GET /api/trakkers/active-alerts
  getTrakkersActiveAlertsForAccount(): Observable<any> {
    return this.api.get("trakkers/active-alerts");
  }
  

  // DELETE /api/alerts/{trakkerEventId}
  deleteTrakkerAlert(trakkerEventId): Observable<any> {
    return this.api.delete("trakkers/alerts/" + trakkerEventId);
  }

  // GET /api/trakkers/favorites
  getFavoriteTrakkers(): Observable<any> {
    return this.api.get("trakkers/favorites");
  }

  // GET /api/trakkers/favorites
  getFavoriteTrakkersNew(): Observable<any> {
    return this.api.get("trakkers/favorites").pipe(retry(1));
  }

  // PUT /api/trakkers/{trakkerId}/favorite
  editTrakkerFavorite(trakkerId, data): Observable<any> {
    return this.api.put("trakkers/" + trakkerId + "/favorite", data).pipe(retry(1));
  }

  // PUT /api/trakkers/{trakkerId}/is-active
  editTrakkerIsActive(trakkerId, data): Observable<any> {
    return this.api.put("trakkers/" + trakkerId + "/is-active", data).pipe(retry(1));
  }

  // GET /api/trakkers/{trakkerId}/in-use/{equipmentNumber}
  checkEquipmentNumberIsInUse(trakkerId, data): Observable<any> {
    return this.api.post("trakkers/" + trakkerId + "/in-use", data ).pipe(retry(1));
  }
  
  // POST /api/trakkers/event/{trakkerEventId}/comment
  saveEventComment(trakkerEventId, data) {
    return this.api.post("trakkers/event/" + trakkerEventId + "/comment", data);
  }

  // OPERATION TIME

  // GET /api/assets  (Get all Assets for logged in user)
  getAllOperationTimeUnits() {
    return this.api.get("assets");
  }

  // GET /api/assets/{assetId}  (Get specified Asset)
  getOperationTimeUnit(unitId) {
    return this.api.get("assets/" + unitId);
  }

  // GET /api/assets/events  (Search Operation Time Events connected to Units)
  getOperationTimeEvents(filterUrl): Observable<any> {
    return this.api.get("assets/events" + filterUrl);
  }

  // POST /api/assets/overtime-events  (Search events outside of working hours for an array of assets)
  getOvertimeEvents(data) {
    return this.api.post("assets/overtime-events", data);
  }

  // POST /api/assets/overtime-events-excel (Export OvertimeReport to Excel) Added: 2025-01-27
  getOvertimeEventsExcel(data): Observable<any> {
    const newParams: any = Object.assign({});
    newParams.responseType = "blob";
    return this.api.post("assets/overtime-events-excel", data, newParams);
  }

  // POST /api/assets/summary  (Get data for Vehicle Summary) NEW POST CALL INSTEAD OF GET
  getOperationTimeSummary(data) {
    return this.api.post("assets/summary", data);
  }

  // GET /api/assets/events/{eventId}  (Get specified Operation Time Event)
  getOperationTimeEvent(eventId) {
    return this.api.get("assets/events/" + eventId);
  }

  // PUT /api/assets/events/{eventId}/comment  (Updates Comment for specified Operation Time Event)
  editOperationTimeEventComment(eventId, data): Observable<any> {
    return this.api.put("assets/events/" + eventId + "/comment", data);
  }

  
  // POST /api/assets/emissions  (Get CO2 emissions for selected Assets)
  getAssetEmissionsAndUsage(data) {
    return this.api.post("assets/emissions", data);
  }

  // NEW ASSET CALLS 2021-03-16

  // POST /api/job/asset-operation-time-reminder  (Create Asset Operation Time Reminder Job for Customer)
  createAssetOperationTimeReminderJob(data) {
    return this.api.post("job/asset-operation-time-reminder", data);
  }

  // PUT /api/job/asset-operation-time-reminder  (Update Asset Operation Time Reminder Job)
  editAssetOperationTimeReminder(data): Observable<any> {
    return this.api.put("job/asset-operation-time-reminder", data);
  }

  // POST /api/job/asset-service-reminder  (Create Asset Service Reminder Job)
  createAssetServiceReminderJob(data) {
    return this.api.post("job/asset-service-reminder", data);
  }

  // PUT /api/job/asset-service-reminder  (Update Asset Service Reminder Job)
  editAssetServiceReminder(data): Observable<any> {
    return this.api.put("job/asset-service-reminder", data);
  }


} // END SERVICE
